import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { KibanaService } from '../../services/requests/kibana.service';
import { SaveKibanaLogAction, SetKibanaDataAction } from './kibana.actions';
import { areObjectsEqual } from 'src/app/shared/helpers/utils';
import { append, patch } from '@ngxs/store/operators';
import { SessionState } from '../session/session.state';

export interface KibanaStateModel {
  data: Record<string, any>[];
}

const KIBANA_STATE_TOKEN = new StateToken<KibanaStateModel>('kibana');

@State<KibanaStateModel>({
  name: KIBANA_STATE_TOKEN,
  defaults: {
    data: []
  }
})
@Injectable()
export class KibanaState {
  constructor(private kibanaService: KibanaService, private store: Store) {}

  @Action(SaveKibanaLogAction)
  saveKibanaLogAction(
    { getState, patchState }: StateContext<KibanaStateModel>,
    { payload }: SaveKibanaLogAction
  ) {
    const { data } = getState();

    if (data.length > 0 && payload.trackingId === 'page-read') {
      const lastData = data.slice(-1)[0];
      if (payload.objectData) {
        payload.objectData = {
          ...payload.objectData,
          ...lastData
        };
      } else {
        payload.objectData = {
          ...lastData
        };
      }

      patchState({
        data: [...Array.from(data.slice(0, -1))]
      });
    }

    const identifier =
      this.store.selectSnapshot(SessionState.identifier) || 'unidentified';

    return this.kibanaService.postKibanaLog({
      guestIdentifier: identifier,
      ...payload
    });
  }

  @Action(SetKibanaDataAction)
  setKibanaDataAction(
    { getState, setState }: StateContext<KibanaStateModel>,
    { payload }: SetKibanaDataAction
  ) {
    const { data } = getState();

    if (payload && Object.keys(payload).length) {
      const isDuplicated = data.some((stateData) => {
        return areObjectsEqual(stateData, payload);
      });

      if (isDuplicated) {
        return;
      }

      setState(
        patch({
          data: append([payload as { pageType: string; customData: object }])
        })
      );
    }
  }
}
