export class SetBookIdAction {
  static readonly type = '[Book] Set Book Id';
  constructor(public payload: number) {}
}

export class GetProfessorContentAction {
  static readonly type = '[Book] Get professor content';
  constructor(
    public payload: {
      bookId: number;
    }
  ) {}
}

export class GetSummaryAction {
  static readonly type = '[Book] Get book summary';
  constructor(
    public payload: {
      bookId: number;
      externalSerieId: number;
    }
  ) {}
}
