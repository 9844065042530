import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { LoadingOverlayService } from 'src/app/shared/components/loading-overlay/loading-overlay.service';
import { Store } from '@ngxs/store';
import { SessionState } from '../../store/session/session.state';
import { LoginAction } from '../../store/session/session.actions';

@Injectable({
  providedIn: 'root'
})
export class InterceptorGuard {
  constructor(
    private router: Router,
    private store: Store,
    private loadingOverlayService: LoadingOverlayService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const guestKey = route.queryParamMap.get('guestKey');

    return this._authenticate$(guestKey);
  }

  private _authenticate$(
    guestKey: string | null
  ): Observable<boolean | UrlTree> | UrlTree {
    if (!guestKey) {
      return this.router.createUrlTree(['404']);
    }

    this.loadingOverlayService.open();

    return this.store.select(SessionState.isAuthenticated).pipe(
      switchMap((isAuthenticated) => {
        return isAuthenticated ? of(true) : this._login$(guestKey);
      })
    );
  }

  private _login$(guestKey: string): Observable<boolean | UrlTree> {
    return this.store
      .dispatch(
        new LoginAction({
          guestKey
        })
      )
      .pipe(
        catchError(() => {
          this.loadingOverlayService.remove();
          return of({
            redirect: '401'
          });
        }),
        map(({ redirect }) => {
          const isAuthenticated = this.store.selectSnapshot(
            SessionState.isAuthenticated
          );
          return (
            isAuthenticated || this.router.createUrlTree([redirect || '401'])
          );
        })
      );
  }
}
