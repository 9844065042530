import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

import { shouldRetry } from 'src/app/shared/rxjs/should-retry';
import { ToastService } from '../services/toast.service';
import {
  LivErrorResponse,
  LivResponseProtocol,
  LivSuccessResponse
} from '../models/liv-response-protocol.model';
import { Store } from '@ngxs/store';

@Injectable()
export class ResponseProtocolInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastService, private store: Store) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<LivSuccessResponse | LivErrorResponse | unknown>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          return event.clone({ body: this.handleResponse(event) });
        }
        return event;
      }),
      retry(
        shouldRetry({
          maxRetryAttempts: 1,
          scalingDuration: 6000,
          excludedStatusCodes: [409, 404, 403, 401, 400]
        })
      ),
      catchError((err: HttpErrorResponse) => this.handleError(err))
    );
  }

  handleResponse(
    event: HttpResponse<any>
  ): LivSuccessResponse | LivErrorResponse {
    const response: LivResponseProtocol = event.body;

    if (
      event.url?.includes('assets') ||
      (event.body as unknown) instanceof Blob
    ) {
      return event.body as LivSuccessResponse;
    }

    if (response.error) {
      throw new HttpErrorResponse({
        status: event.status,
        error: response.error,
        url: event.url ?? undefined
      });
    }

    if ('data' in response) {
      return {
        data: response.data
      };
    }

    throw new HttpErrorResponse({
      status: event.status,
      error: event.body,
      url: event.url ?? undefined
    });
  }

  handleError(response: HttpErrorResponse): Observable<never> {
    if (response.status === HttpStatusCode.InternalServerError) {
      this.toastService.error(
        'Ocorreu um erro inesperado! Tente novamente mais tarde!'
      );
    }

    const errorResponse: LivErrorResponse = {
      status:
        (
          response.error as {
            status: HttpStatusCode;
          }
        )?.status || response.status,
      error: {
        ...response.error,
        stack: {
          url: response.url
        }
      }
    };

    return throwError(() => errorResponse);
  }
}
