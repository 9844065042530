import { Component } from '@angular/core';
import { ConfigService } from './core/services/config.service';
import { of } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Liv Portfolio - Família';

  readonly config$ = of(this.configService.config);

  constructor(private configService: ConfigService) {}
}
