import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  filter,
  map,
  merge,
  of,
  switchMap
} from 'rxjs';
import { EPages } from 'src/app/shared/enums/pages.enum';
import { Store } from '@ngxs/store';

import { PageState, PageStateModel } from 'src/app/core/store/page/page.state';

export interface PagesConfig {
  type: EPages;
  title?: string;
  id?: number;
  index?: number | null;
}

@Injectable({
  providedIn: 'root'
})
export class PageControllerService {
  private initialPages: PagesConfig[] = [
    { type: EPages.intro, index: 1 },
    { type: EPages.categories, index: 2 }
  ];

  private pages: PagesConfig[][] = [this.initialPages];

  private _dynamicPages = new BehaviorSubject<PagesConfig[]>(this.initialPages);

  public dynamicPages$ = this._dynamicPages.asObservable();

  public pages$ = this.store.select(PageState.rightIndexPage).pipe(
    filter((page) => page !== null && page > 2),
    switchMap((page) =>
      of({
        pages: this.pages,
        index: page
      })
    ),
    map(({ pages, index }) => {
      const currentPageIndex = pages.findIndex((_pages) =>
        _pages.find((_page) => _page.index === index)
      );

      const [previous, current] = pages[currentPageIndex] || [];

      return {
        previous,
        current
      };
    })
  );

  public onPageChanged$ = this.store.select(PageState.rightIndexPage).pipe(
    filter((page) => page !== null && page > 2),
    switchMap((page) =>
      of({
        pages: this.pages,
        index: page
      })
    )
  );

  private dynamicPage$ = this.store.select(PageState.rightIndexPage).pipe(
    filter((idxPage) => idxPage !== null && idxPage !== undefined),
    switchMap(() => of(this.pages)),
    map((pages) => {
      const book: Record<'previous' | 'current', PagesConfig> = {
        previous: {} as PagesConfig,
        current: {} as PagesConfig
      };

      let count = 0;

      const { rightIndex, leftIndex } =
        this.store.selectSnapshot<PageStateModel>(PageState);

      const currentPageIndex = pages.findIndex(() => {
        const pos = (count += 2);
        return pos === rightIndex;
      });

      if (currentPageIndex > -1 && this.pages.length > 0) {
        const [previous, current] = this.pages[currentPageIndex];
        book.previous = {
          ...previous,
          index: leftIndex
        };
        this._dynamicPreviousPage.next(book.previous);

        if (current) {
          book.current = { ...current, index: rightIndex };
          this._dynamicCurrentPage.next(book.current);
        }
      }

      return book;
    })
  );

  private _dynamicCurrentPage = new BehaviorSubject<PagesConfig>(
    {} as PagesConfig
  );

  public dynamicCurrentPage$ = this._dynamicCurrentPage.asObservable();

  private _dynamicPreviousPage = new BehaviorSubject<PagesConfig>(
    {} as PagesConfig
  );

  public dynamicPreviousPage$ = this._dynamicPreviousPage.asObservable();

  constructor(private store: Store) {
    this.dynamicPage$.subscribe();
  }

  get snapshot(): {
    dynamicPages: PagesConfig[];
    dynamicPagesCount: number;
  } {
    return {
      dynamicPages: this._dynamicPages.getValue(),
      dynamicPagesCount: this._dynamicPages.getValue().length
    };
  }

  setDynamicPage(page: PagesConfig): void {
    const { dynamicPages } = this.snapshot;

    this._dynamicPages.next([
      ...dynamicPages,
      {
        ...page
      }
    ]);
  }

  setPage(page: PagesConfig, pos: number): void {
    const { dynamicPages } = this.snapshot;

    const pages = this.insertPage(dynamicPages, pos - 1, page);

    this._dynamicPages.next(pages);

    this.buildPages();
  }

  setPages(pages: PagesConfig[]): void {
    this.pages.push(pages);
  }

  buildPages(): void {
    const { dynamicPages } = this.snapshot;
    if (!dynamicPages?.length) {
      return;
    }

    let start: number;
    let end: number;

    const pages: PagesConfig[][] = [];

    for (let index = 0; index < dynamicPages.length; index += 2) {
      start = index;
      end = start + 2;

      pages.push(dynamicPages.slice(start, end));
    }

    this.pages = pages;
  }

  private insertPage = (
    pages: PagesConfig[],
    index: number,
    page: PagesConfig
  ) => {
    pages.splice(index, 0, {
      ...page
    });

    return pages.map((page, i) => ({ ...page, index: i + 1 }));
  };

  listenOncePage$(pageType: EPages): Observable<PagesConfig> {
    return merge(this.dynamicCurrentPage$, this.dynamicPreviousPage$).pipe(
      filter((page) => page !== null && page !== undefined),
      filter((page) => page.type === pageType)
    );
  }

  listenPages$(pageTypes: EPages[]) {
    return merge(this.dynamicCurrentPage$, this.dynamicPreviousPage$).pipe(
      filter((page) => page !== null && page !== undefined),
      filter((page) => pageTypes.includes(page.type))
    );
  }

  get lessonRegistersPages(): PagesConfig[] {
    return this.snapshot.dynamicPages.filter(
      (page) => page.type === EPages.lesson
    );
  }
}
