import { Injectable } from '@angular/core';
import { ApiGatewayService } from '../api-gateway.service';
import {
  BookSummaryModel,
  BookSummaryRequestModel,
  BookSummaryResponseModel,
  ProfessorBookContentResponseModel
} from '../../models/book.model';
import { ETypesComponentStrapi } from 'src/app/shared/enums/types-component-strapi.enum';
import { Observable, map } from 'rxjs';
import { WrappedModel } from '../../models/liv-response-protocol.model';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BookService {
  constructor(private apiGatewayService: ApiGatewayService) {}

  public getSummary({
    externalSerieId,
    bookId
  }: BookSummaryRequestModel): Observable<BookSummaryModel> {
    return this.apiGatewayService
      .get<WrappedModel<BookSummaryResponseModel>>(
        `/livros/summary/familia/${externalSerieId}/${bookId}`
      )
      .pipe(
        map(({ data }) => {
          const { paginas } = data.attributes || {};

          return {
            pages: {
              categories: paginas.categorias.map((category) => {
                return {
                  category: {
                    id: category.categoria.id,
                    color: category.categoria.cor
                  },
                  lessons: category.aulas.map((lesson) => {
                    return {
                      id: lesson.aula_id,
                      category_id: lesson.categoria_id
                    };
                  })
                };
              }),
              creativeSpaces: paginas.espacos.map((creativeSpace) => {
                return {
                  id: creativeSpace.id,
                  title: creativeSpace.titulo,
                  description: creativeSpace.descricao
                };
              })
            }
          };
        })
      );
  }

  public getProfessorContent(
    bookId: string,
    populate: ETypesComponentStrapi[] = [
      ETypesComponentStrapi.serie,
      ETypesComponentStrapi.class,
      ETypesComponentStrapi.professor_midia
    ]
  ): Observable<WrappedModel<ProfessorBookContentResponseModel>> {
    let params = new HttpParams();

    if (populate?.length) {
      const filters = populate.join(',');
      params = params.set('populate', filters);
    }

    return this.apiGatewayService
      .get<WrappedModel<ProfessorBookContentResponseModel>>(
        `/livros/${bookId}`,
        {
          params
        }
      )
      .pipe(map(({ data }) => data));
  }
}
