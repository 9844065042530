import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { httpInterceptorProviders } from './interceptors';
import { registerLocaleData } from '@angular/common';

import localeBr from '@angular/common/locales/pt';
import { HotToastModule } from '@ngneat/hot-toast';
import { ToastService } from './services/toast.service';
import { ApiGatewayService } from './services/api-gateway.service';
import { WindowResizeService } from './services/window-resize.service';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { BookState } from './store/book/book.state';
import { PageState } from './store/page/page.state';

import { StorageService } from './services/storage.service';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { decrypt, encrypt } from './security/state-cypher';

import { NgIconsModule } from '@ng-icons/core';
import {
  featherEdit3,
  featherChevronRight,
  featherX,
  featherArrowLeft,
  featherArrowRight
} from '@ng-icons/feather-icons';

import { ErrorHandlerService } from './errors/services/error-handler.service';
import { GlobalErrorHandler } from './errors/GlobalErrorHandler';
import { SessionState } from './store/session/session.state';
import { CategoryState } from './store/category/category.state';
import { KibanaState } from './store/kibana/kibana.state';
import { TimerService } from './services/timer.service';
import { SurveyState } from './store/survey/survey.state';

registerLocaleData(localeBr, 'pt-BR');

const PERSISTED_STATES = [BookState, SessionState, CategoryState, PageState];
const STATES = [...PERSISTED_STATES, KibanaState, SurveyState];

@NgModule({
  imports: [
    HttpClientModule,
    HotToastModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      serialize: (value: any) =>
        environment.production ? encrypt(value) : JSON.stringify(value),
      deserialize: (value: string) =>
        environment.production ? decrypt(value) : JSON.parse(value),
      storage: StorageOption.SessionStorage,
      namespace: '@liv-famly-pfo',
      key: [...PERSISTED_STATES]
    }),
    NgxsModule.forRoot(STATES, {
      developmentMode: !environment.production,
      selectorOptions: {
        injectContainerState: false // https://www.ngxs.io/advanced/options (injectContainerState false é recomendado p/ melhor performance)
      }
    }),
    NgIconsModule.withIcons({
      featherEdit3,
      featherChevronRight,
      featherX,
      featherArrowLeft,
      featherArrowRight
    })
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    httpInterceptorProviders,
    ApiGatewayService,
    StorageService,
    ErrorHandlerService,
    GlobalErrorHandler,
    ToastService,
    TimerService,
    WindowResizeService
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
